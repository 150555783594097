import { XCell, XCellBorders, XCellStyle, XFill, XFontStyle } from "../hooks/useExcelDataApi.types";

export const setNumberFormat = (cell: Excel.Range, cellData: XCell, defaultDateFormat: string) => {
  if (cellData.format?.numberFormat && cellData.format.numberFormat.category) {
    const format =
      cellData.format.numberFormat.category === Excel.NumberFormatCategory.date
        ? (defaultDateFormat || cellData.format.numberFormat.code) + ";@"
        : cellData.format.numberFormat.code;
    cell.numberFormat = [[format]];
  } else {
    cell.numberFormat = [["@"]];
  }
};

export const setCommonFormatOptions = (format: Excel.CellPropertiesFormat, styles?: XCellStyle) => {
  if (styles?.alignment?.wrapText !== undefined) {
    format!.wrapText = styles.alignment.wrapText;
  }
  if (styles?.alignment?.horizontal !== undefined) {
    format!.horizontalAlignment = styles.alignment.horizontal;
  }
  if (styles?.alignment?.vertical !== undefined) {
    format!.verticalAlignment = styles.alignment.vertical;
  }
  if (styles?.alignment?.indent !== undefined) {
    format!.indentLevel = styles.alignment.indent;
  }
};

export const setFill = (format?: Excel.CellPropertiesFormat, fill?: XFill) => {
  if (!format || !fill) {
    return;
  }
  if (fill.patternType !== undefined) {
    (format.fill ??= {}).pattern = fill.patternType;
  }
  if (fill.foregroundColor !== undefined) {
    (format.fill ??= {}).color = fill.foregroundColor;
  }
  if (fill.backgroundColor !== undefined) {
    (format.fill ??= {}).patternColor = fill.backgroundColor;
  }
};

export const setFont = (format?: Excel.CellPropertiesFormat, font?: XFontStyle) => {
  if (!format || !font) {
    return;
  }
  if (font.bold !== undefined) {
    (format.font ??= {}).bold = font.bold;
  }
  if (font.color !== undefined) {
    (format.font ??= {}).color = font.color;
  }
  if (font.italic !== undefined) {
    (format.font ??= {}).italic = font.italic;
  }
  if (font.name !== undefined) {
    (format.font ??= {}).name = font.name;
  }
  if (font.size !== undefined) {
    (format.font ??= {}).size = font.size;
  }
  if (font.strike !== undefined) {
    (format.font ??= {}).strikethrough = font.strike;
  }
  if (font.underline !== undefined) {
    (format.font ??= {}).underline = font.underline;
  }
};

export const setBorder = (format?: Excel.CellPropertiesFormat, borders?: XCellBorders) => {
  if (!format || !borders) {
    return;
  }
  if (borders.bottom !== undefined) {
    (format.borders ??= {}).bottom = {
      style: borders.bottom.style,
      color: borders.bottom.color,
      weight: borders.bottom.weight,
    };
  }
  if (borders.left !== undefined) {
    (format.borders ??= {}).left = {
      style: borders.left.style,
      color: borders.left.color,
      weight: borders.left.weight,
    };
  }
  if (borders.right !== undefined) {
    (format.borders ??= {}).right = {
      style: borders.right.style,
      color: borders.right.color,
      weight: borders.right.weight,
    };
  }
  if (borders.top !== undefined) {
    (format.borders ??= {}).top = {
      style: borders.top.style,
      color: borders.top.color,
      weight: borders.top.weight,
    };
  }
};
