/* eslint-disable @typescript-eslint/no-explicit-any */
import { Root, createRoot } from "react-dom/client";
import Entry from "./Entry";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { defined } from "./utils/typeHelper";

let isOfficeInitialized = false;

const title = "Entrilia for Excel";

/** office.js cleans up history functionality due to using IE10 for Outlook addins. In result router doesn't work.
 * MS plans to fix this in the future, but for now we need to restore history functionality.
 * This function restores history functionality and then calls Office.onReady() callback.
 * https://github.com/OfficeDev/office-js/pull/2808
 */
function boot(): Promise<void> {
  const replaceState = window.history.replaceState;
  const pushState = window.history.pushState;

  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js";
    script.onload = () => {
      Office.onReady(() => {
        window.history.replaceState = replaceState;
        window.history.pushState = pushState;

        resolve();
      });
    };

    document.body.appendChild(script);
  });
}

let root: Root | null = null;

// eslint-disable-next-line no-undef
const render = (Component: JSX.Element | any) => {
  const container = defined(document.getElementById("container"));
  if (root === null) {
    root = createRoot(container);
  }
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Component title={title} isOfficeInitialized={isOfficeInitialized} />
      </BrowserRouter>
    </React.StrictMode>
  );
};

/* Render application after Office initializes */
boot().then(() => {
  Office.onReady(() => {
    isOfficeInitialized = true;
    OfficeExtension.config.extendedErrorLogging = true;
    render(Entry);
  });
});

if ((module as any).hot) {
  (module as any).hot.accept("./Entry", () => {
    const NextEntry = Entry;
    render(NextEntry);
  });
}
